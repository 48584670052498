/* eslint-disable react/jsx-pascal-case */
import React, { useContext, useEffect, useState } from "react";
// import { _Table } from "edviron-ui-package";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CREATE_INSTITUTE,
  GENERATE_SCHOOL_TOKEN,
  GET_SCHOOLS,
  RESET_KEY,
  KYC_LOGON_TOKEN,
} from "../../../../Qurries";
import { GrPowerReset } from "react-icons/gr";
import Pagination from "../../../../components/Pagination/Pagination";
import { FaRegClipboard } from "react-icons/fa6";
import { toast } from "react-toastify";
import { LOGIN_TO_MERCHANT_WITH_TRUSTEE, SENT_KYC_INVITE } from "./Qurries";
import ConfirmationBox from "../../../../components/ConfermationBox";
import Modal from "../../../../components/Modal/Modal";
import { _Table } from "../../../../components/Table";
import SchoolsUploadViaCsv from "./SchoolsUploadViaCsv";
import {
  Form,
  Input,
  preventNegativeValues,
  preventPasteNegative,
} from "edviron-ui-package";
import { MdContentCopy } from "react-icons/md";
import { Access, dashboardContext } from "../../Dashboard";
import ToolTip from "../../../../components/ToolTip";
import CopyRight from "../../../../components/CopyRight";
import { Link, useLocation } from "react-router-dom";
import { HiMiniXMark } from "react-icons/hi2";
interface ResetKeys {
  [schoolId: string]: string; // Map school IDs to reset keys (both strings)
}

export const handleCopyContent = (content: any) => {
  navigator.clipboard
    .writeText(content)
    .then(() => {
      toast.success("Copied to clipboard");
    })
    .catch((err) => {
      toast.error("Error while copying");
    });
};

function SchoolList() {
  const [page, setPage] = useState(1);
  const location = useLocation();
  const [kycStatus, setKycStatus] = useState("");
  useEffect(() => {
    if (location.state === "Inactive") {
      setKycStatus("Not Initiated");
    }
    if (location.state === "Active(KYC pending)") {
      setKycStatus("Documents uploaded");
    }
    if (location.state === "Active(KYC done)") {
      setKycStatus("KYC Approved");
    }
  }, [location.state]);

  const { user } = useContext(dashboardContext);
  const [sendKycLinkConfirmationModal, setSendKycLinkConfirmationModal] =
    useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [schoolDetails, setSchoolDetails] = useState({
    school_name: "",
    school_id: "",
  });
  const { data, loading, refetch } = useQuery(GET_SCHOOLS, {
    variables: {
      page: page,
    },
  });
  const schools =
    data && data.getSchoolQuery ? data.getSchoolQuery.schools : [];

  const totalPageNo =
    data && data.getSchoolQuery
      ? data.getSchoolQuery.total_pages || null
      : null;

  const [createInstitute, { data: result, loading: LOAD, error: _ERROR }] =
    useMutation(CREATE_INSTITUTE, {
      refetchQueries: [
        {
          query: GET_SCHOOLS,
          variables: { page },
        },
      ],
      errorPolicy: "ignore",
    });

  const [sentKycInvite] = useMutation(SENT_KYC_INVITE);
  const [logInToMerchant] = useMutation(LOGIN_TO_MERCHANT_WITH_TRUSTEE);
  const [searchQuery, setSearchQuery] = useState("");
  const handlePageChange = (pageNumber: any) => {
    setPage(pageNumber);
  };

  useEffect(() => {
    refetch({
      page: page,
    });
  });

  const [KYCSchoolId, setKYCSchoolId] = useState(null);
  const [
    validateAdminRefetch,
    { data: validateAdmin, loading: validateAdminLoading },
  ] = useLazyQuery(KYC_LOGON_TOKEN, {
    variables: { school_id: KYCSchoolId },
  });

  const handleKYCDashboard = async (school_id: any) => {
    setKYCSchoolId(school_id);

    const res = await validateAdminRefetch({
      variables: { school_id: school_id },
    });
    if (res.data?.kycLoginToken.token) {
      window.open(
        `${process.env.REACT_APP_KYC_DASHBOARD_URL}/admin?token=${res.data.kycLoginToken.token}`,
      );
    }
  };

  const column =
    user?.getUserQuery?.role === Access.ADMIN ||
    user?.getUserQuery?.role === Access.OWNER
      ? [
          "S.No.",
          "Institute Name",
          "Email",
          "KYC Status",
          "Action",
          "PG Key",
          "KYC Dashboard",
          "Merchant Dashboard",
        ]
      : user?.getUserQuery?.role === Access.FINANCE_TEAM ||
          user?.getUserQuery?.role === Access.DEVELOPER
        ? [
            "S.No.",
            "Institute Name",
            "Email",
            "KYC Status",
            "Merchant Dashboard",
          ]
        : ["S.No.", "Institute Name", "Email", "KYC Status"];

  return (
    <div className="w-full p-2">
      <Modal
        open={sendKycLinkConfirmationModal}
        setOpen={setSendKycLinkConfirmationModal}
        className="max-w-2xl"
      >
        <ConfirmationBox
          setOpen={setSendKycLinkConfirmationModal}
          funtion={async () => {
            const res = await sentKycInvite({
              variables: {
                school_name: schoolDetails.school_name,
                school_id: schoolDetails.school_id,
              },
            });

            if (res.data.sentKycInvite) {
              toast.success("KYC Invite Sent");
            }
          }}
          confirmationText="resend the link"
          ButtonText="Resend Email"
        />
      </Modal>
      <div className="w-full flex flex-col ">
        <_Table
          exportBtn={true}
          perPage={true}
          heading={"Institute List"}
          srNo={false}
          pagination={true}
          filter={[searchQuery]}
          searchBox={
            <div className="flex flex-col w-full">
              <div className="flex justify-between items-center mt-2 gap-x-2 w-full">
                <div className="bg-[#EEF1F6] py-3 items-center flex  px-6 max-w-md w-full rounded-lg">
                  <i className="text-edvion_black text-opacity-50 fa-solid text-xs fa-magnifying-glass"></i>
                  <input
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                    type="text"
                    placeholder="Search (Institute Name, Email ID...)"
                    className="ml-4 text-xs focus:outline-none w-full placeholder:font-normal bg-[#EEF1F6]"
                  />
                </div>
                {(user?.getUserQuery?.role === Access.ADMIN ||
                  user?.getUserQuery?.role === Access.OWNER) && (
                  <div className="flex ">
                    <SchoolsUploadViaCsv page={page} />
                    <button
                      onClick={() => setShowModal(!showModal)}
                      className="py-2 bg-edviron_black text-sm rounded-[4px] text-white float-right px-6 ml-2"
                    >
                      + Add Institute
                    </button>
                  </div>
                )}
              </div>
              {kycStatus !== "" && (
                <div className=" text-sm m-2  max-w-fit ">
                  <button
                    onClick={() => {
                      setKycStatus("");
                    }}
                    className="bg-[#6687FFCC] font-medium flex items-center rounded-lg text-white px-4 py-2 h-full w-full"
                  >
                    {kycStatus} <HiMiniXMark className=" text-lg ml-1" />
                  </button>
                </div>
              )}
            </div>
          }
          data={[
            column,
            ...schools
              .filter((d: any) => {
                const arr = [
                  d.school_name,
                  d.email,
                  d.school_id,
                  d.pg_key,
                ].join(",");
                return (
                  (!kycStatus || d.merchantStatus === kycStatus) &&
                  (!searchQuery ||
                    arr.toLowerCase().includes(searchQuery.toLowerCase()))
                );
              })
              .map((school: any, index: number) => [
                <div className="ml-4">{index + 1}</div>,
                <Link to="vendor" state={{ schoolId: school.school_id }}>
                  <div
                    className="flex justify-between items-center"
                    key={school.school_id}
                  >
                    <ToolTip text={school.school_name}>
                      <div className="w-full truncate">
                        {school.school_name}
                      </div>
                    </ToolTip>
                    <button
                      onClick={() => {
                        handleCopyContent(school.school_id);
                      }}
                    >
                      <ToolTip text="Copy Institute ID">
                        <MdContentCopy
                          className="cursor-pointer text-[#717171] shrink-0 text-xl"
                          style={{
                            fontSize: "22px",
                            color: "",
                            backgroundColor: "transparent",
                          }}
                        />
                      </ToolTip>
                    </button>
                  </div>
                </Link>,
                <div className="truncate" title={school.email}>
                  {school.email ? school.email : "NA"}
                </div>,

                <div className="truncate">
                  {" "}
                  {school.merchantStatus ? school.merchantStatus : "NA"}
                </div>,
                (user?.getUserQuery?.role === Access.ADMIN ||
                  user?.getUserQuery?.role === Access.OWNER) && (
                  <button
                    disabled={school.pg_key}
                    className="px-4 py-2 border disabled:border-gray-400 disabled:text-gray-400 border-edviron_black text-[#6687FF] font-normal rounded-[4px]"
                    onClick={() => {
                      setSendKycLinkConfirmationModal(true);
                      setSchoolDetails({
                        school_name: school.school_name,
                        school_id: school.school_id,
                      });
                    }}
                  >
                    Resend Email
                  </button>
                ),
                (user?.getUserQuery?.role === Access.ADMIN ||
                  user?.getUserQuery?.role === Access.OWNER) && (
                  <>
                    {school.pg_key ? (
                      <div className="flex justify-between gap-x-2 items-center">
                        <p className="bg-[#EEF1F6] w-full py-1.5 px-4 rounded-[4px]">
                          {school.pg_key}
                        </p>
                        <button
                          onClick={() => {
                            handleCopyContent(school.pg_key);
                          }}
                        >
                          <ToolTip text="Copy PG KEY">
                            <MdContentCopy
                              className="cursor-pointer text-[#717171] shrink-0 text-xl"
                              style={{
                                fontSize: "22px",
                                color: "",
                                backgroundColor: "transparent",
                              }}
                            />
                          </ToolTip>
                        </button>
                      </div>
                    ) : (
                      <p className="bg-gray-100  py-1.5 px-4 rounded-[4px]">
                        PG key is not enabled
                      </p>
                    )}
                  </>
                ),
                (user?.getUserQuery?.role === Access.ADMIN ||
                  user?.getUserQuery?.role === Access.OWNER) && (
                  <button
                    disabled={school.pg_key}
                    className="px-4 py-2 border disabled:border-gray-400 disabled:text-gray-400 border-edviron_black text-[#6687FF] font-normal rounded-[4px]"
                    onClick={() => {
                      handleKYCDashboard(school.school_id);
                    }}
                  >
                    Login to Dashboard
                  </button>
                ),
                (user?.getUserQuery?.role === Access.ADMIN ||
                  user?.getUserQuery?.role === Access.OWNER ||
                  user?.getUserQuery?.role === Access.FINANCE_TEAM ||
                  user?.getUserQuery?.role === Access.DEVELOPER) && (
                  <button
                    disabled={!school.pg_key || !school.email}
                    className="px-4 py-2 border disabled:border-gray-400 disabled:text-gray-400 border-edviron_black text-[#6687FF] font-normal rounded-[4px]"
                    onClick={async () => {
                      try {
                        const res = await logInToMerchant({
                          variables: {
                            email: school.email,
                          },
                        });

                        if (res?.data?.generateMerchantLoginToken) {
                          window.open(
                            `${process.env.REACT_APP_MERCHANT_DASHBOARD_URL}/admin?token=${res?.data?.generateMerchantLoginToken}`,
                            "_blank",
                          );
                        }
                      } catch (err) {}
                    }}
                  >
                    Login to Dashboard
                  </button>
                ),
              ]),
          ]}
        />
        <Modal
          className="max-w-lg w-full"
          open={showModal}
          setOpen={setShowModal}
          title="Create Education Institute"
        >
          <Form
            onSubmit={async (data: any) => {
              const res = await createInstitute({
                variables: {
                  email: data?.Email,
                  school_name: data["Education Institute"],
                  phone_number: data["Phone Number"],
                  admin_name: data["Admin Name"] || "NA",
                },
              });
              if (res.data) {
                toast.success("New School created successfully");
              }

              setShowModal(false);
            }}
          >
            <Input
              type="name"
              placeholder="Enter Institute Name"
              name="Education Institute"
              add_error={() => {}}
              required
            />
            <Input
              type="email"
              name="Email"
              placeholder="Enter Email"
              add_error={() => {}}
              required
            />
            <Input
              type="number"
              name="Phone Number"
              add_error={() => {}}
              placeholder="Enter Phone no"
              onKeyDown={preventNegativeValues}
              onPaste={preventPasteNegative}
              min={0}
              maxLength={10}
              required
            />
            <Input
              type="name"
              name="Admin Name"
              add_error={() => {}}
              value="NA"
              placeholder="Enter Admin Name"
              required
            />

            <div className="mt-2 mb-2 text-center">
              <button className="py-2 px-16 max-w-[15rem] w-full rounded-lg disabled:bg-blue-300 bg-edviron_black text-white">
                Create Institute
              </button>
            </div>
          </Form>
        </Modal>
      </div>
    </div>
  );
}

export default SchoolList;
