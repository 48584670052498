/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useRef, useState } from "react";
// import { _Table } from 'edviron-ui-package'
import { _Table } from "../../../components/Table";
// import Table from './Table';
import Select, { components } from "react-select";
import {
  GET_SCHOOLS,
  GET_SETTLEMENT_REPORTS,
  GET_ALL_SCHOOLS_QUERY,
} from "../../../Qurries";
import { useQuery } from "@apollo/client";
import { FaX } from "react-icons/fa6";
import { useLazyQuery } from "@apollo/client";
import { IoSearch } from "react-icons/io5";
import { LiaRupeeSignSolid } from "react-icons/lia";
// import Modal from "../../../../components/Modal/Modal";
import { toast } from "react-toastify";
import { DateRange } from "react-date-range-ts";
import { IoIosArrowDown } from "react-icons/io";
import AllFilters from "../Transaction/components/AllFilter/AllFilters";
import Filters from "../Transaction/components/Filters";
import { amountFormat } from "../../../utils/amountFormat";
import { Link } from "react-router-dom";

export const CustomDropdownIndicator = () => {
  return (
    <div>
      <IoIosArrowDown className="text-xs w-8 text-[#1E1B59]" />
    </div>
  );
};

const Settlement = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust the number of items per page as needed
  const [filters, setFilters] = useState([null]);
  const [settlementStatusFilter, setSettlementStatusFilter] = useState("");
  const [selectDays, setSelectDays] = useState(0);
  const [school, setSchools] = useState([{ school_id: null }]);
  const [schoolId, setSchoolId] = useState([null]);
  const [SettlementReportsData, setSettlementReportsData] = useState([]);
  const [schoolData, setSchoolData] = useState([]);
  const [unsettledAmount, setUnsettledAmount] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showCustomDateModel, setShowCustomDateModelset] = useState(false);
  const [dateDropDown, setDateDropDown] = useState(false);
  const [schoolSearchQuery, setSchoolSearchQuery] = useState("");
  const [dateFilterType, setDateFilterType] = useState<string>("");

  const [checkboxFilter, setCheckboxFilter] = useState<any>({
    size: 0,
    status: 0,
    mode: 0,
  });
  const [dateFilter, setDateFilter] = useState<{
    type: string;
    startDate: string;
    endDate: string;
  }>({
    type: "",
    startDate: new Date(0).toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }),
    endDate: new Date().toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }),
  });
  const [selectStatus, setSelectStatus] = useState<any>({
    Settled: false,
    Pending: false,
  });
  const [selectedTime, setSelectedTime] = useState<string>("Date");

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(""),
      key: "selection",
    },
  ]);
  const {
    data: schoolsData,
    loading: schoolsLoading,
    refetch: refetchSchools,
  } = useQuery(GET_ALL_SCHOOLS_QUERY, {
    onCompleted: () => {
      // Call GET_SETTLEMENT_REPORTS after GET_ALL_SCHOOLS_QUERY is completed
      getSettlementReports();
    },
  });

  const handleClearTimeRange = () => {
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(""),
        key: "selection",
      },
    ]);
    setEndDate("");
  };
  // const {
  //   data: settlementReportData,
  //   loading: settlementLoading,
  //   refetch: settlementRefetch,
  // } = useQuery(GET_SETTLEMENT_REPORTS, {
  //   onCompleted: (data) => {

  //     if (data.getSettlementReports.length > 0) {
  //       // setSchoolData(data.getSettlementReports);
  //       // setSettlements(data.getSettlementReports);

  //       const totalPrice = data.getSettlementReports
  //         .map((adj: any) => adj.adjustment)
  //         .reduce((acc: any, adjustment: any) => acc + adjustment, 0);

  //       setUnsettledAmount(totalPrice);
  //     }
  //   },
  // });
  let updatedSettlementData: [];
  const [
    getSettlementReports,
    {
      data: settlementReportData,
      loading: settlementLoading,
      refetch: settlementRefetch,
    },
  ] = useLazyQuery(GET_SETTLEMENT_REPORTS, {
    onCompleted: (data) => {
      updatedSettlementData = data.getSettlementReports.map((report: any) => {
        const matchingSchool = schoolsData.getAllSchoolQuery.find(
          (school: any) => school.school_id === report.schoolId,
        );

        // Add schoolName property to the settlement report item
        return {
          ...report,
          schoolName: matchingSchool
            ? matchingSchool.school_name
            : "Unknown School",
        };
      });
      setSettlementReportsData(updatedSettlementData);
      setSchoolData(updatedSettlementData);
      if (data.getSettlementReports.length > 0) {
        const totalPrice = data.getSettlementReports
          .map((adj: any) => adj.adjustment)
          .reduce((acc: any, adjustment: any) => acc + adjustment, 0);

        setUnsettledAmount(totalPrice);
      }
    },
  });

  const schools =
    schoolsData?.getAllSchoolQuery?.map((school: any) => ({
      school_name: school.school_name,
      school_id: school.school_id,
    })) || [];

  const schoolsList = schools.map((school: any) => {
    return { label: school.school_name, value: school.school_name };
  });
  const [filteredSchools, setFilteredSchools] = useState([]);
  useEffect(() => {
    const filtered = schools.filter((school: any) =>
      school.school_name
        .toLowerCase()
        .includes(schoolSearchQuery.toLowerCase()),
    );
    setFilteredSchools(filtered);
  }, [schoolSearchQuery]);

  useEffect(() => {
    setCurrentPage(1);

    if (schoolData) {
      const currentDate = new Date(endDate);

      const updatedFilteredData = schoolData.filter((data: any) => {
        // Filter by date range if selectDays is not null

        const dateRangeFilter =
          selectDays !== 0
            ? filterByDateRange(data, currentDate, selectDays)
            : true;

        // Filter by search query
        const searchQueryFilter =
          searchQuery === "" || dataContainsQuery(data, searchQuery);

        // Filter By School Name
        const schoolFilter =
          schoolId.length === 1 || filterBySchoolName(data, schoolId);

        // Filter by Status
        // const statusFilter = filters.length == 1 || filterByStatus(data, filters)
        const statusFilter =
          settlementStatusFilter !== ""
            ? data.status === settlementStatusFilter ||
              (data.status === "SUCCESS" &&
                settlementStatusFilter === "Settled")
            : true;

        // Combine both filters
        return (
          dateRangeFilter && searchQueryFilter && schoolFilter && statusFilter
        );
      });

      setSettlementReportsData(updatedFilteredData);
    }
  }, [
    settlementReportData,
    selectDays,
    searchQuery,
    schoolId,
    filters,
    settlementStatusFilter,
    endDate,
    startDate,
    schoolData,
  ]);

  const isDateInRange = (
    dateToCheck: any,
    rangeStartDate: any,
    rangeEndDate: any,
  ) => {
    // Implement your date range comparison logic here
    return dateToCheck >= rangeStartDate && dateToCheck <= rangeEndDate;
  };

  useEffect(() => {
    setCurrentPage(1);
    const unsettledAmountSum = SettlementReportsData.map(
      (adj: any) => adj.adjustment,
    ).reduce((acc: any, adjustment: any) => acc + adjustment, 0);

    setUnsettledAmount(unsettledAmountSum);
  }, [SettlementReportsData]);

  // Helper function to filter by date range
  const filterByDateRange = (
    data: any,
    currentDate: Date,
    selectDays: number,
  ) => {
    const settlementDate = new Date(data.settlementDate);

    const timeDifference: number =
      currentDate.getTime() - settlementDate.getTime();

    const daysDifference = timeDifference / (1000 * 3600 * 24);

    if (daysDifference < 0) {
      return false;
    }
    return daysDifference <= selectDays;
  };

  const filterBySchoolName = (data: any, schoolId: any[]) => {
    return schoolId.length === 1 || schoolId.includes(data.schoolName);
  };
  const filterByStatus = (data: any, filter: any[]) => {
    return filter.length === 1 || filter.includes(data.status);
  };

  const dateOptions = [
    {
      name: "Today",
      value: 1,
    },
    {
      name: "Last 7 Days",
      value: 7,
    },
    {
      name: "Last 1 Month",
      value: 30,
    },
    {
      name: "Last 1 Year",
      value: 365,
    },
  ];

  const filter2 = [
    {
      name: "Settled",
    },
    {
      name: "Pending",
    },
  ];

  const daysOptions = dateOptions.map((filter) => {
    return { label: filter.name, value: filter.value };
  });

  const statusFilter: any = [
    { value: "Settled", label: "Settled" },
    { value: "Pending", label: "Pending" },
  ];
  const stats = filter2.map((status) => {
    return { label: status.name, value: status.name };
  });

  const handleSeach = () => {};

  const handleFilterChange = (selectedFilter: any) => {
    // Check if the selected filter is already in the state
    setSettlementStatusFilter(selectedFilter.value);

    let check: any = selectedFilter.value;
    let checkFilter: any = filters;

    if (!checkFilter.includes(check)) {
      setFilters([...filters, check]);
    }
  };

  const dataContainsQuery = (data: any, query: string) => {
    return Object.values(data).some((value: any) =>
      value.toString().toLowerCase().includes(query.toLowerCase()),
    );
  };

  const totalPages = Math.ceil(schoolData.length / itemsPerPage);
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1,
  );

  const paginatedData = schoolData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  const handleSchoolFilterChange = (selectedFilter: any) => {
    // Check if the selected filter is already in the state
    let check: any = selectedFilter?.value;
    let checkFilter: any = schoolId;
    // setSettlementStatusFilter(selectedFilter.value)
    if (!checkFilter.includes(check)) {
      setSchoolId([...schoolId, check]);
    }
  };
  const removeFilter = (index: any) => {
    // Create a new array without the filter at the specified index
    const updatedFilters = filters.filter((_, i) => i !== index);
    setFilters(updatedFilters);
  };

  const removeSchoolFilter = (index: any) => {
    const updateSchoolFilter = schoolId.filter((_, i) => i !== index);
    setSchoolId(updateSchoolFilter);
  };
  const handleApplyClick = () => {
    if (startDate && endDate) {
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);

      // Calculate the difference in milliseconds
      const timeDifference = endDateObj.getTime() - startDateObj.getTime();

      // Convert milliseconds to days (1 day = 24 * 60 * 60 * 1000 milliseconds)
      const daysDifference = Math.ceil(timeDifference / (24 * 60 * 60 * 1000));

      setStartDate(startDate);
      setEndDate(endDate);
      setShowCustomDateModelset(!showCustomDateModel);
      setSelectDays(daysDifference);
      setDateDropDown(!dateDropDown);
    } else {
      // Handle case where either startDate or endDate is null
      toast.error("Both start and end dates are required");
      console.error("Both start and end dates are required.");
    }
  };

  const { mode, size, status, ...allOtherFilter } = checkboxFilter;
  const handleDeleteStatusFilters = (status: string) => {
    setCheckboxFilter((pre: any) => {
      delete pre[status?.toLowerCase()];
      return { ...pre, size: pre.size - 1, status: pre.status - 1 };
    });
    status = status.charAt(0).toUpperCase() + status.substring(1);
    setSelectStatus((pre: any) => {
      return { ...pre, [status]: false };
    });
  };

  return (
    <div className="overflow-hidden">
      <h2 className="text-[#1B163B] text-[28px] font-[600]">Settlements</h2>

      <div className="w-full -ml-2 flex justify-between items-end">
        <div className="text-[#229635] font-[400] flex ">
          {/* <span className="text-[48px] flex items-center">
            <LiaRupeeSignSolid />{" "}
            {unsettledAmount !== null ? (
              <span>{unsettledAmount}</span>
            ) : (
              <span>0</span>
            )}
          </span>
          <span className="text-[20px] text-[#717171] flex items-center ml-2">
            {` (unsettled amount)`}
          </span> */}
        </div>
        <div className="">
          <div className="text-[14px] font-[400] text-right m-0">
            settlement cycle
          </div>
          <div className="text-[20px] font-[600] text-[#6687FF] m-0 mt-0">
            1 working day
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col overflow-x-auto mt-1">
        {SettlementReportsData ? (
          <_Table
            perPage={true}
            exportBtn={true}
            heading={"History"}
            srNo={true}
            pagination={true}
            copyContent={[9]}
            filter={[searchQuery]}
            searchBox={
              <div className="flex flex-col w-full">
                <div className="flex xl:!flex-row flex-col gap-2  w-full xl:items-center items-start mb-2 justify-between">
                  <div className="bg-[#EEF1F6] py-3 items-center flex  px-6 xl:max-w-md max-w-[34rem] w-full rounded-lg">
                    <i className="text-edvion_black text-opacity-50 fa-solid text-xs fa-magnifying-glass"></i>
                    <input
                      type="text"
                      className="ml-4 text-xs bg-transparent focus:outline-none w-full placeholder:font-normal"
                      onFocus={(e) =>
                        (e.target.style.borderColor = "transparent")
                      }
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                  <div className="flex items-center xl:max-w-lg w-full">
                    {/* Date Filter */}
                    <Filters
                      selectedTime={selectedTime}
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                      transaction={false}
                      setSettlementStatusFilter={setSettlementStatusFilter}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      startDate={startDate}
                      endDate={endDate}
                      setSelectDays={setSelectDays}
                      setDateDropDown={setDateDropDown}
                      dateDropDown={dateDropDown}
                      setDateFilterType={setDateFilterType}
                      dateFilterType={dateFilterType}
                    />
                    {/* Status Filter */}
                    {/* <div className="">
                      <Select
                        className="font-normal px-2 rounded-lg "
                        options={statusFilter}
                        value={null}
                        // onChange={handleStatusChange}
                        // onChange={handleFilterChange}
                        onChange={(selectedOption: any) => {
                          setSettlementStatusFilter(selectedOption.value);
                          // setSchoolData(settlements)
                        }}
                        // onChange={setSettlementStatusFilter}
                        placeholder={
                          <div className="text-[#1E1B59] text-xs">Status</div>
                        }
                        styles={{
                          control: (provided: any) => ({
                            ...provided,
                            backgroundColor: "##F6F8FA",
                            border: "1px solid #6687FF",
                            width: "180px",
                          }),
                          input: (provided: any) => ({
                            ...provided,
                            backgroundColor: "transparent",
                            "::placeholder": {
                              backgroundColor: "#YourDesiredColor",
                              opacity: 1,
                            },
                          }),
                        }}
                      />
                    </div> */}
                    {/* School Filter */}
                    <div className="w-full min-w-[180px] max-w-[180px]">
                      {schoolsList ? (
                        <Select
                          className="font-normal m-0 p-2"
                          options={schoolsList}
                          components={{
                            DropdownIndicator: CustomDropdownIndicator,
                            IndicatorSeparator: () => null,
                          }}
                          onChange={handleSchoolFilterChange}
                          placeholder={
                            <div className="text-[#1E1B59] text-xs">
                              Select Institute
                            </div>
                          }
                          value={null}
                          styles={{
                            control: (provided: any) => ({
                              ...provided,
                              backgroundColor: "#F6F8FA",
                              border: "1px solid #1B163B",
                              borderRadius: "6px",

                              minHeight: "15px",
                              margin: "0px",
                              color: "#6687FF",
                            }),
                            valueContainer: (provided: any) => ({
                              ...provided,
                              padding: "0px",
                              paddingLeft: "0.5rem",
                            }),
                            input: (provided: any) => ({
                              ...provided,
                              backgroundColor: "transparent",
                              color: "#000",
                              "::placeholder": {
                                backgroundColor: "#YourDesiredColor",
                                opacity: 1,
                              },
                              placeholder: (provided: any) => ({
                                ...provided,
                                color: "red", // Set the color of the placeholder text
                              }),
                            }),
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="flex ">
                  {filters.map(
                    (filter, index) =>
                      filter !== null && (
                        <div
                          className="bg-[#6687FFCC] text-[16px] m-2 rounded-[8px] w-[131px] h-[40px] flex items-center justify-around"
                          key={index}
                        >
                          <span className="text-white">{filter}</span>
                          <span>
                            <FaX
                              className="text-white cursor-pointer"
                              onClick={() => removeFilter(index)}
                            />
                          </span>
                        </div>
                      ),
                  )}
                  {schoolId.map(
                    (school, index) =>
                      school !== null && (
                        <div
                          className="bg-[#6687FFCC] text-sm m-2 rounded-lg min-w-max h-10 px-2 flex items-center gap-x-2"
                          style={{ maxWidth: "8em" }}
                          key={index}
                        >
                          <span className="text-white truncate pl-2">
                            {school}
                          </span>
                          <span>
                            <FaX
                              className="text-white cursor-pointer h-3"
                              onClick={() => removeSchoolFilter(index)}
                            />
                          </span>
                        </div>
                      ),
                  )}
                  {selectDays !== 0 && (
                    <div className="bg-[#6687FFCC] text-sm m-2 rounded-lg min-w-max h-10 px-2 flex items-center gap-x-2">
                      <span className="text-white">{dateFilterType}</span>
                      <span>
                        <FaX
                          className="text-white cursor-pointer h-3"
                          onClick={() => {
                            setDateFilterType("");
                            setDateRange([
                              {
                                startDate: new Date(),
                                endDate: new Date(""),
                                key: "selection",
                              },
                            ]);
                            setEndDate("");
                            setSelectDays(0);
                          }}
                        />
                      </span>
                    </div>
                  )}
                  {settlementStatusFilter !== "" && (
                    <div className="bg-[#6687FFCC] text-sm m-2 rounded-lg min-w-max h-10 px-2 flex items-center gap-x-2">
                      <span className="text-white">
                        {settlementStatusFilter}
                      </span>
                      <span>
                        <FaX
                          className="text-white cursor-pointer h-3"
                          onClick={() => setSettlementStatusFilter("")}
                        />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            }
            data={[
              [
                "Institute Name",
                "Settlement Amount",
                "Adjustment",
                "Payment Amount",
                "From",
                "Till",
                "Status",
                "UTR No",
                "Settlement Date",
              ],

              ...SettlementReportsData?.map((data: any, index: any) => [
                <Link
                  to="/payments/settlements-transaction"
                  state={{ utrno: data.utrNumber }}
                >
                  <div className="truncate">{data.schoolName}</div>
                </Link>,

                <div className=" truncate">
                  {amountFormat(data.settlementAmount)}
                </div>,

                <div className=" truncate">{data.adjustment}</div>,

                <div className=" truncate">
                  {amountFormat(data.netSettlementAmount)}
                </div>,

                <div className=" truncate">
                  {new Date(data.fromDate).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}
                </div>,

                <div className=" truncate">
                  {/* {data.Till} */}
                  {new Date(data.tillDate).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}
                </div>,

                <div
                  className={
                    " truncate " +
                    (data.status.toLowerCase() === "settled" ||
                    data.status.toLowerCase() === "success"
                      ? "text-[#04B521]"
                      : "")
                  }
                >
                  {data.status.toLowerCase() === "settled" ||
                  data.status.toLowerCase() === "success"
                    ? "Settled"
                    : data.status}
                </div>,
                <div
                  className=" truncate overflow-hidden"
                  style={{ maxWidth: "5em" }}
                  title={data.utrNumber}
                >
                  {data.utrNumber}
                </div>,

                <div className={" truncate "}>
                  {/* {data.SettlementDate} */}
                  {new Date(data.settlementDate).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}
                </div>,
              ]),
            ]}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Settlement;
