export const getStartAndEndOfMonth = () => {
  const currentDate = new Date();

  const istOffset = 5.5 * 60 * 60 * 1000;
  const istDate = new Date(currentDate.getTime() + istOffset);

  const todayDate = istDate.toISOString().split("T")[0];

  const startDate = new Date(istDate.getFullYear(), istDate.getMonth());
  const formattedStartDate = new Date(startDate.getTime() + istOffset)
    .toISOString()
    .split("T")[0];

  const endDate = new Date(istDate.getFullYear(), istDate.getMonth() + 1, 1);

  const adjustedEndDate = new Date(endDate.getTime() - 1);
  const formattedEndDate = new Date(adjustedEndDate.getTime() + istOffset)
    .toISOString()
    .split("T")[0];

  return {
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    currentDate: todayDate,
  };
};

export function getPreviousMonthYear() {
  const currentDate = new Date();
  let month = currentDate.getMonth() + 1;
  let year = currentDate.getFullYear();

  if (month === 1) {
    month = 12;
    year -= 1;
  } else {
    month -= 1;
  }

  return { year, month };
}

export function getDateRange(month: number, year: number) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const firstDate = new Date(year, month - 1, 1);
  const lastDate = new Date(year, month, 0);

  const startDate = `${String(firstDate.getDate()).padStart(2, "0")} ${monthNames[firstDate.getMonth()]} ${firstDate.getFullYear()}`;
  const endDate = `${String(lastDate.getDate()).padStart(2, "0")} ${monthNames[lastDate.getMonth()]} ${lastDate.getFullYear()}`;

  return `${startDate} - ${endDate}`;
}
